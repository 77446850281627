<template>
  <div class="index"
       v-loading.fullscreen.lock="fullscreenLoading">
    <!-- ========操作中心======== -->
    <div class="operatingCenter">
      <div class="op_btn_view">
        <el-button @click="skipRouter('order')">订单查看</el-button>
        <el-button @click="skipRouter('Pack')">打包处理</el-button>
        <el-button @click="skipRouter('ComeOut')">出入库</el-button>
        <el-button @click="skipRouter('concentratedRK')">集运入库</el-button>
        <el-button @click="skipRouter('positions')"
                   v-if="userQX=='admin'||userQX=='ckadmin'">仓位管理</el-button>
        <el-button @click="skipRouter('accountM')"
                   v-if="userQX=='admin'||userQX=='ckadmin'">仓库账号管理</el-button>

      </div>
    </div>

    <!-- ========数据中心======== -->
    <div class="informationCenter">
      <!-- ========数据展示区域======== -->
      <div class="dataCenter"
           :class="activePage=='warehouse'?'minPage':''">
        <!-- 订单统计 -->
        <div class="order_number">
          <div class="view_title">
            <i class="el-icon-star-off"></i>
            <span>订单统计</span>
          </div>
          <div class="order_view">

            <div class="number_item">
              <span class="progress_title">待入库：</span>
              <div class="progress_view">
                <el-progress :percentage="orderStatistical[0]"
                             status="success"
                             :show-text='false'>
                </el-progress>
              </div>
              <el-button type="text">{{orderStatistical[0]}}件</el-button>
            </div>

            <div class="number_item">
              <span class="progress_title">已入库：</span>
              <div class="progress_view">
                <el-progress :percentage="orderStatistical[1]"
                             status="success"
                             :show-text='false'>
                </el-progress>
              </div>
              <el-button type="text">{{orderStatistical[1]}}件</el-button>
            </div>

            <div class="number_item">
              <span class="progress_title">待打包：</span>
              <div class="progress_view">
                <el-progress :percentage="orderStatistical[2]"
                             status="success"
                             :show-text='false'>
                </el-progress>
              </div>
              <el-button type="text">{{orderStatistical[2]}}件</el-button>
            </div>

            <div class="number_item">
              <span class="progress_title">待出库：</span>
              <div class="progress_view">
                <el-progress :percentage="orderStatistical[3]"
                             status="success"
                             :show-text='false'>
                </el-progress>
              </div>
              <el-button type="text">{{orderStatistical[3]}}件</el-button>
            </div>

          </div>
        </div>
        <!-- 仓库总订单 -->
        <div class="allOrder">
          <div class="view_title">
            <i class="el-icon-star-off"></i>
            <span>仓库储存订单量</span>
          </div>
          <div class="order_view">
            <el-progress type="dashboard"
                         :percentage="warehouseStatistical/5"
                         :width='200'
                         :stroke-width='10'>
              <template #default="">
                <span class="percentage-value">仓库总订单{{ warehouseStatistical }}件</span>
              </template>
            </el-progress>
          </div>

        </div>
        <!-- 近期入库订单量 -->
        <div class="putStorage">

          <div class="view_title">
            <i class="el-icon-star-off"></i>
            <span>近期出入库订单量</span>
          </div>
          <div class="putStorage_view"
               ref="bar">

          </div>
        </div>
      </div>

      <!-- ========仓库位置信息区======== -->
      <div class="warehouseLocation"
           :class="activePage=='warehouse'?'activePage_s':''"
           @click="tabPage">
        <transition name="el-zoom-in-bottom">
          <div class="tableBtn"
               v-if="activePage=='warehouse'">
            <el-button type="primary"
                       icon="el-icon-close"
                       @click.stop="restorePage"></el-button>
          </div>
        </transition>

        <el-table :data="tableData"
                  height="750"
                  style="width: 100%">
          <el-table-column prop="id"
                           label="订单ID"
                           width="70">
          </el-table-column>
          <el-table-column label="订单类型"
                           v-if="activePage=='warehouse'"
                           width="130">
            <template #default="scope">
              <span>{{ scope.row.type==1?'商业' :'集运'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="国家"
                           v-if="activePage=='warehouse'"
                           width="120">
            <template #default='scope'>
              <span v-if="scope.row.gjname">{{scope.row.gjname}}</span>
              <span v-else
                    style="color:rgba(255,255,255,.5)">{{'未选择'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="寄件人"
                           v-if="activePage=='warehouse'"
                           width="100">
            <template #default='scope'>
              <span v-if="scope.row.jjname">{{scope.row.jjname}}</span>
              <span v-else
                    style="color:rgba(255,255,255,.5)">{{'未选择'}}</span>
            </template>

          </el-table-column>
          <el-table-column prop="bgnum"
                           v-if="activePage=='warehouse'"
                           label="包裹数量"
                           width="100">
          </el-table-column>
          <el-table-column label="物流单号">
            <template #default="scope">
              <span v-if="scope.row.wlnum==0"
                    style="color:rgba(255,255,255,.5)">选报价后生成</span>
              <span v-else
                    class="iscopy"
                    :class="'copy'+ scope.row.id"
                    @click="copy(scope.row.id)"
                    :data-clipboard-text="scope.row.wlnum">{{scope.row.wlnum}}</span>
            </template>
          </el-table-column>
          <el-table-column label="快递单号">
            <template #default="scope">
              <span v-if="scope.row.kdnum==0"
                    style="color:rgba(255,255,255,.5)">只有自寄和打包前有</span>
              <span v-else
                    class="iscopy"
                    :class="'copy'+ scope.row.id"
                    @click="copy(scope.row.id)"
                    :data-clipboard-text="scope.row.kdnum">{{scope.row.kdnum}}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态"
                           width="130">
            <template #default="scope">
              <el-tag :type=" parseInt(scope.row.status,10) <0? 'danger':'primary'"
                      disable-transitions>{{scope.row.zt}}</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { postOrder, postOrder_hz } from '@/request/api'
import Clipboard from 'clipboard'

export default {
  name: 'Index',
  data () {
    return {
      userQX: '',
      // ------------当前用户是否为超级管理员------------
      isAdmin: false,
      // ------------加载中------------
      fullscreenLoading: false,
      // ------------当前显示的大页面内容------------
      activePage: '',
      // ------------仓库ID------------
      wid: '',
      // ------------订单数据------------
      tableData: [],
      // ------------订单统计数据------------
      orderStatistical: [0, 0, 0, 0],
      // ------------仓库总订单数量------------
      warehouseStatistical: 0,
      // ------------指定图表的配置项和数据------------
      option: {
        title: {
        },
        tooltip: {
          show: true,
          item: 'axis',
        },
        aria: {
          enabled: true,
          show: true
        },
        legend: {
          data: ['出库', '入库']
        },
        xAxis: {
          type: 'category',
          data: ["4/16", "4/17", "4/18", "4/19", "4/20", "4/21", "4/22"],
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '出库',
          type: 'bar',
          data: [5, 15, 30, 10, 30, 20, 13],
          //   color: "#fff"
        }, {
          name: '入库',
          type: 'bar',
          data: [5, 20, 36, 30, 10, 20, 21]
        }]
      }
    }
  },
  methods: {

    //  ------获取汇总订单信息-------
    async getCollect () {
      this.fullscreenLoading = true
      let res = await postOrder_hz({
        ckid: this.wid
      })
      if (res.errno == 0) {
        this.orderStatistical[0] = res.drk ? parseInt(res.drk, 10) : 0
        this.orderStatistical[1] = res.yrk ? parseInt(res.yrk, 10) : 0
        this.orderStatistical[2] = res.ddb ? parseInt(res.ddb, 10) : 0
        this.orderStatistical[3] = res.dck ? parseInt(res.drk, 10) : 0

        let data = [];
        let ck = [];
        let rk = [];
        for (const key in res.day) {
          data.unshift(key.replace(/-/i, '/'));
          ck.unshift(parseInt(res.day[key].ck, 10))
          rk.unshift(parseInt(res.day[key].rk, 10))
        }
        this.option.series[0].data = ck
        this.option.series[1].data = rk
        this.option.xAxis.data = data;
      }
      this.initializeData()

    },
    //  ------点击复制-------
    copy (e) {
      if (this.activePage == 'warehouse') {
        let clipboard = new Clipboard('.copy' + e);
        clipboard.on('success', e => {
          this.$message.success("复制成功 ：" + e.text);
          // 释放内存
          clipboard.destroy()
        })
        clipboard.on('error', e => {
          // 不支持复制
          this.$message.warning("该浏览器不支持自动复制 ：" + e.text);
          // 释放内存
          clipboard.destroy()
        })
      }

    },
    //   ------------还原页面------------
    restorePage () {
      this.activePage = ''
    },
    //   ------------切换显示大页面------------
    tabPage () {
      this.activePage = 'warehouse'
    },
    //   ------------获取仓库订单(这里只展示部分数据)------------
    async getOrder () {
      this.fullscreenLoading = true
      let res = await postOrder({
        op: 'list',
        pd: 'getlist',
        ckid: this.wid,
        page: 1,
        pageSize: 12
      })
      if (res.errno == 0) {
        this.tableData = res.orderlist
        this.warehouseStatistical = parseInt(res.totalNum, 10)
      }
      this.fullscreenLoading = false
    },
    //   ------------路由跳转------------
    skipRouter (name) {
      this.$router.push({
        name,
      })
    },
    //   ------------初始化近期入库订单------------
    initializeData () {
      // 初始化echarts实例
      let myEcharts = this.$echarts.init(this.$refs.bar)
      myEcharts.setOption(this.option)
    }
  },
  async created () {
    this.userQX = JSON.parse(localStorage.getItem('tuangyin_ck_userinfo')).role
    let wid = JSON.parse(localStorage.getItem('ty_warehouseData'))
    let cklist = JSON.parse(localStorage.getItem('cklist'))
    //  --------- 
    if (cklist) {
      if (wid) {
        this.wid = wid.id
      } else {
        this.wid = cklist.cklist[0].id
      }
      this.getOrder()
      this.getCollect()

    } else {
      await this.$store.dispatch('cklist')
      localStorage.setItem('cklist', JSON.stringify(this.$store.state.warehouse))
      cklist = this.$store.state.warehouse
      if (wid) {
        this.wid = wid.id
      } else {
        this.wid = cklist.cklist[0].id
      }
      this.getOrder()
      this.getCollect()
    }
  },
  mounted () {

  }
}
</script>

<style lang='scss'>
.index {
  width: 100%;
  height: 100%;
  display: flex;
  .operatingCenter {
    flex: 1;
    background-color: #c2ddfe;
    padding: 20px;
    box-sizing: border-box;
    max-width: 300px;
    .op_btn_view {
      //   background-color: pink;
      height: 100%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      //   justify-content: space-around;

      height: 740px;
      .el-button {
        max-height: 120px;
        flex: 1;
        margin: 10px 0;
        height: 100px;
        border: 0;
        border-radius: 20px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      }
      .el-button + .el-button {
        margin-left: 0;
      }
    }
  }

  //   ------------数据中心------------
  .informationCenter {
    padding: 10px 20px 10px 10px;
    flex: 2;
    display: flex;
    justify-content: space-between;
    //   ------------数据统计------------
    .minPage {
      width: 0px !important;
      //   height: 0 !important;
      overflow: hidden;
    }
    .dataCenter {
      height: 750px;
      width: 400px;
      transition: all 0.5s;
      display: flex;
      flex-direction: column;
      //   justify-content: space-between;
      //   min-height: 800px;
      .allOrder,
      .putStorage,
      .order_number {
        padding: 10px;
        box-sizing: border-box;
        width: 400px;
        border-radius: 10px;
        // opacity: 0.8;
        margin-bottom: 30px;
        .view_title {
          font-size: 18px;
          font-weight: 700;
          color: #fefefe;
          letter-spacing: 1px;
          .el-icon-star-off {
            margin-right: 5px;
          }
        }
      }
      .putStorage {
        background-color: rgb(233, 233, 233);
        box-shadow: 7px 13px 12px 0 rgba(233, 233, 233, 0.5);
        .putStorage_view {
          margin-top: 10px;
          width: 380px;
          height: 200px;
        }
        .view_title {
          color: #4c90fd;
        }
      }
      .allOrder {
        background-color: #57c421;
        box-shadow: 7px 13px 12px 0 rgba(86, 191, 32, 0.5);
        .order_view {
          margin-top: 20px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .percentage-value {
            color: #fff;
            font-size: 20px;
            font-weight: 700;
            letter-spacing: 2px;
          }
        }
      }
      .order_number {
        box-shadow: 7px 13px 12px 0 rgba(111, 152, 252, 0.5);
        background-color: #4c90fd;
        .order_view {
          color: #fff;
          margin-top: 10px;
          .number_item {
            display: flex;
            align-items: center;
            cursor: pointer;
            .progress_title {
              font-size: 14px;
              font-weight: 700;
            }
            .progress_view {
              width: 70%;
              margin: 0 10px;
            }
            .el-button {
              // height: 30px;
              min-height: 28px;
              padding: 0;
              color: #fff;
            }
          }
        }
      }
    }
    .activePage_s {
      width: 800px !important;
      height: 800px !important;
      margin-left: 0 !important;
    }
    //   ------------仓库可视化------------
    .warehouseLocation {
      width: 400px;
      height: 750px;
      flex: 1;
      border-radius: 10px;
      margin-left: 50px;
      background-color: #5280fb;
      box-shadow: 7px 13px 12px 0 #527ffb6b;
      overflow: hidden;
      transition: all 0.5s;
      .tableBtn {
        .el-button {
          border-radius: 0 0px 10px 0;
        }
      }
      .el-table--enable-row-hover {
        .el-table__body tr {
          &:hover {
            background: rgb(184, 224, 250);
            border: 1px solid #313463;
          }
        }
      }
      .el-table {
        tbody tr {
          &:hover {
            td {
              background-color: #3354f8fb;
            }
          }
        }
      }

      td {
        color: #eee;
      }
      .el-table td,
      .el-table th.is-leaf {
        border-bottom: 1px solid #546ff94f;
      }
      .el-table--border::after,
      .el-table--group::after,
      .el-table::before {
        background-color: rgba(255, 255, 255, 0);
      }
      .el-table thead,
      .el-table__empty-text {
        color: #fff;
      }
      .el-table th,
      .el-table tr {
        background-color: #527ffb00 !important;
      }
      .el-table,
      .el-table__expanded-cell {
        background-color: #5280fb !important;
      }
    }
  }
}
</style>